<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <div class="modal-header">
      <span class="com-title">{{ title }}</span>
    </div>
    <div v-if="msg" class="msg">{{ msg }}</div>
    <div id="errorContainer">
      <div v-if="getErrors" class="error">{{ getErrors('savingError') }}</div>
    </div>
    <div v-if="showNewAccountScreen && !loading" class="modal-body">
      <form>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('accountName') }}</div>
          </div>
          <label for="name">Name <span class="required">*</span></label>
          <input
            id="name"
            ref="nameInput"
            v-model="accountData.name"
            type="text"
            aria-describedby="emailHelp"
            @input="validateAccountName"
          />
        </div>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('accountAbb') }}</div>
          </div>
          <label for="abbreviation">Abbreviation <span class="required">*</span></label>
          <input
            id="abbreviation"
            ref="abbInput"
            v-model="accountData.abbreviation"
            type="text"
            @input="validateAccountAbbreviation"
          />
        </div>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('accountPrefix') }}</div>
          </div>
          <label for="prefix">Prefix <span class="required">*</span></label>
          <input
            id="prefix"
            ref="prefixInput"
            v-model="accountData.prefix"
            type="text"
            @input="validateAccountPrefix"
          />
        </div>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('countryCode') }}</div>
          </div>
          <label for="countryCode">Country Code<span class="required">*</span></label>
          <input
            id="countryCode"
            ref="countryCodeInput"
            v-model="accountData.countryCode"
            type="text"
            @input="validateAccountCountryCode"
          />
        </div>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('currency') }}</div>
          </div>
          <label for="currency">Currency <span class="required">*</span></label>
          <input
            id="currency"
            ref="currencyInput"
            v-model="accountData.currency"
            type="text"
            @input="validateAccountCurrency"
          />
        </div>
        <div class="form-group lg-control-box">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('accountParentId') }}</div>
          </div>
          <label for="parentId"
            >Parent Account<span v-if="isExternalAdmin" class="required">*</span></label
          >
          <k-select
            ref="parentIdInput"
            v-model="accountData.parentId"
            :options="sortedAccounts"
            label-key="name"
            :multiple="false"
            :value="selectedParentId"
            :searchable="true"
            :show-clear-selection-btn="true"
            label="Please select Parent Account"
            :class="[isAccAlert ? '' : 'errorBox']"
            @input="onParentIdSelect"
          />
        </div>
        <div class="form-group form-check">
          <label class="form-check-label" for="userEnabled">Active</label>
          <k-checkbox
            :checked="accountData.active"
            name="accountData.active"
            @change="setActive"
            @selected="setActive"
          />
        </div>

        <div class="form-group form-check">
          <label class="form-check-label" for="colorEnabled">White Label</label>
          <k-checkbox
            id="colorEnabled"
            :checked="whitelabelEnabled"
            name="whitelabelEnabled"
            @change="setColorEnabled"
            @selected="setColorEnabled"
          />
        </div>

        <div v-if="whitelabelEnabled">
          <div class="form-group form-check">
            <label class="form-check-label" for="emailBranding">Apply Email Branding</label>
            <k-checkbox
              id="emailBranding"
              :checked="accountData.organization.emailBranding"
              name="applyEmailBranding"
              @change="setEmailBranding"
              @selected="setEmailBranding"
            />
          </div>
          <div class="form-group color-group">
            <label for="primaryColor">Brand Color</label>
            <label
              for="primaryColor"
              class="color-input-holder"
              @click="showPrimaryColorPicker = !showPrimaryColorPicker"
              >{{ primaryColor }}</label
            >
            <div v-if="showPrimaryColorPicker" class="colorpicker-account">
              <font-awesome-icon
                :icon="['far', 'times']"
                class="colorpicker-close"
                @click="showPrimaryColorPicker = false"
              />
              <chrome-picker
                v-if="showPrimaryColorPicker"
                name="primaryColor"
                :value="primaryColor"
                @input="handleColorChange($event, 'primaryColor')"
              />
            </div>
          </div>
          <div class="form-group color-group overtext">
            <label for="overText">Button Text</label>
            <div class="overtext-dropdown-container">
              <b-select
                :options="buttonTextOptions"
                :selected="buttonText"
                class="b-select-dd"
                :show-tool-tip="false"
                @change="handleOverTextChange"
              />

              <k-button
                :size="3"
                label="BUTTON PREVIEW"
                class="ml-20"
                :container-style="buttonPreviewStyles"
              />
            </div>
          </div>

          <div class="file-form-group">
            <div class="file-group-label">
              Logo
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!fileToUpload"
                :class="{ dragging: dragging, error: fileError.fileToUpload }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="onFileChange($event, 'fileToUpload')"
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="file-input"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 380 x 60
                  (pixels width by height)<br /><br />
                  PNG/Transparent Background only</label
                >
                <input
                  id="file-input"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="onFileChange($event, 'fileToUpload')"
                />
              </div>
              <div v-if="!!fileError.fileToUpload" class="error file-error-msg">
                {{ fileError.fileToUpload }}
              </div>
              <div v-if="!!fileToUpload" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="handleFileReset('fileToUpload')"
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="fileToUpload.src" />
                  <div v-if="uploadPercentage.fileToUpload" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.fileToUpload}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.fileToUpload}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.fileToUpload }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="file-form-group">
            <div class="file-group-label">
              AppConsole Logo
              <p>{{ `(For white backgrounds)` }}</p>
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!appConsoleLogoFile"
                :class="{ dragging: dragging, error: fileError.appConsoleLogoFile }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="onFileChange($event, 'appConsoleLogoFile')"
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="app-console-file"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 300 x 140
                  (pixels width by height)<br /><br />
                  PNG/Transparent Background only</label
                >
                <input
                  id="app-console-file"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="onFileChange($event, 'appConsoleLogoFile')"
                />
              </div>
              <div v-if="!!fileError.appConsoleLogoFile" class="error file-error-msg">
                {{ fileError.appConsoleLogoFile }}
              </div>
              <div v-if="!!appConsoleLogoFile" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="handleFileReset('appConsoleLogoFile')"
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="appConsoleLogoFile.src" />
                  <div v-if="uploadPercentage.appConsoleLogoFile" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.appConsoleLogoFile}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.appConsoleLogoFile}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.appConsoleLogoFile }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="file-form-group">
            <div class="file-group-label">
              AppConsole Square Icon
              <p>{{ `(For white backgrounds)` }}</p>
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!appConsoleSquareLogoFile"
                :class="{ dragging: dragging, error: fileError.appConsoleSquareLogoFile }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="onFileChange($event, 'appConsoleSquareLogoFile')"
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="square-upload-icon"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 90 x 90
                  (pixels width by height)<br /><br />
                  Square format only PNG/Transparent Background only</label
                >
                <input
                  id="square-upload-icon"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="onFileChange($event, 'appConsoleSquareLogoFile')"
                />
              </div>
              <div v-if="!!fileError.appConsoleSquareLogoFile" class="error file-error-msg">
                {{ fileError.appConsoleSquareLogoFile }}
              </div>
              <div v-if="!!appConsoleSquareLogoFile" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="handleFileReset('appConsoleSquareLogoFile')"
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="appConsoleSquareLogoFile.src" />
                  <div v-if="uploadPercentage.appConsoleSquareLogoFile" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.appConsoleSquareLogoFile}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.appConsoleSquareLogoFile}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.appConsoleSquareLogoFile }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="white-label-domain">
            <label class="form-check-label" for="colorEnabled">Whitelabel Domain</label>
            <k-checkbox
              id="subdomainEnabled"
              :checked="accountData.organization.subdomainEnabled"
              name="subdomainEnabled"
              @change="setBrandingDomain"
              @selected="setBrandingDomain"
            />
          </div>
          <div v-if="accountData.organization.subdomainEnabled" class="file-form-group">
            <div class="file-group-label">
              FavIcon
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!favIconLogoFile"
                :class="{ dragging: dragging, error: fileError.favIconLogoFile }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="onFileChange($event, 'favIconLogoFile')"
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="favicon-upload-icon"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 90 x 90
                  (pixels width by height)<br /><br />
                  Square format only PNG/ICO/Transparent Background only</label
                >
                <input
                  id="favicon-upload-icon"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="onFileChange($event, 'favIconLogoFile')"
                />
              </div>
              <div v-if="!!fileError.favIconLogoFile" class="error file-error-msg">
                {{ fileError.favIconLogoFile }}
              </div>
              <div v-if="!!favIconLogoFile" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="handleFileReset('favIconLogoFile')"
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="favIconLogoFile.src" />
                  <div v-if="uploadPercentage.favIconLogoFile" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.favIconLogoFile}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.favIconLogoFile}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.favIconLogoFile }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="accountData.organization.subdomainEnabled"
            class="form-group whitelabelbrandingContainer"
          >
            <label class="white-label-domain-destination" for="colorEnabled">
              Whitelabel Domain Destination
            </label>
            <div>
              <div class="white-label-subDomain-container mr-10">
                <input
                  class="radio-input-btn "
                  type="radio"
                  :checked="accountDomainOpt === 'accountDomainUrl'"
                  @click="handleAccountDomainRadioChange"
                />
                <div
                  class="account-domain-url"
                  :class="accountDomainOpt !== 'accountDomainUrl' ? 'disabled-input-text' : ''"
                >
                  <input
                    id="accountDomainUrl"
                    ref="accountDomainUrlInput"
                    v-model="
                      accountDomainOpt !== 'accountDomainUrl' ? '' : whiteLabelDomainData.domainName
                    "
                    :disabled="accountDomainOpt !== 'accountDomainUrl'"
                    type="text"
                    @input="validateAccountSubdomainUrl"
                    @change="handleAccountDomainUrlChange"
                  />
                  <label class="form-check-label" for="colorEnabled">
                    .dashboardsandreports.com
                  </label>
                </div>
              </div>
              <div class="white-label-subDomain-container">
                <input
                  class="radio-input-btn"
                  type="radio"
                  :checked="accountDomainOpt === 'customDomainUrl'"
                  @click="handleCustomDomainRadioChange"
                />
                <input
                  id="customDomainUrl"
                  ref="customDomainUrlInput"
                  v-model="
                    accountDomainOpt !== 'customDomainUrl' ? '' : whiteLabelDomainData.domainName
                  "
                  class="custom-domain-input"
                  :class="accountDomainOpt !== 'customDomainUrl' ? 'disabled-input-text' : ''"
                  :disabled="accountDomainOpt !== 'customDomainUrl'"
                  type="text"
                  @input="validateAccountCustomUrl"
                  @change="handleAccountCustomUrlChange"
                />
              </div>
            </div>
          </div>
          <!-- <div
            v-if="accountData.organization.subdomainEnabled"
            class="form-group application-container"
          >
            <label class="application-text">Application</label>
            <div class="application-dropdown-container">
             <k-select
                ref="applcationOption"
                :options="applicationOptions"
                label-key="application-option"
                :multiple="false"
                :value="applicationOption"
                :searchable="false"
                label="Please select Application"
                @input="handleApplicationOptionChange"
              />
              <b-select
                :options="applicationOptions"
                :selected="applicationOption"
                class="b-select-dd"
                :show-tool-tip="false"
                @change="handleApplicationOptionChange"
              />
            </div>
          </div> -->
          <!-- <div class="form-group favicon-container">
            <label class="form-check-label margin-right" for="colorEnabled"> Favicon </label>
            <div class="favicon-input-container">
              <b-favicon-input
                :value="
                  accountData.organization.faviconUrl ? accountData.organization.faviconUrl : ''
                "
                @on-change="handleFaviconUrlChange"
              />
              <label class="form-check-label account-domain-label" for="colorEnabled">
                Enter account's domain to pull favicon from, ex. https://bigagency.com
              </label>
            </div>
          </div> -->
        </div>

        <br />
        <div class="form-group action-footer">
          <div class="actions-btn">
            <k-button :size="3" label="SAVE" class="mr-20" @click="saveAccount" />
            <k-button :size="3" label="CLOSE" @click="close" />
          </div>
        </div>
      </form>
    </div>
    <div v-if="showNewAdvertiserScreen && !loading" class="modal-body">
      <form>
        <div class="form-group">
          <label for="name">Account</label>
          <label class="value">{{ createdAccount.name }}</label>
        </div>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('advName') }}</div>
          </div>
          <label for="name">Advertiser Name <span class="required">*</span></label>
          <input
            id="advname"
            ref="advnameInput"
            v-model="advertiserData.name"
            type="text"
            aria-describedby="emailHelp"
            @input="validateAdvertiserName"
          />
          <div v-if="matchingAdvertisers.length > 0" class="advertiser-matches">
            <ul class="dropdown dropdown-menu">
              <li v-for="(match, index) in matchingAdvertisers" :key="index">{{ match.name }}</li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <div id="errorContainer">
            <div v-if="getErrors" class="error">{{ getErrors('xandrId') }}</div>
          </div>
          <label for="xandrID">Xandr ID</label>
          <input
            id="xandrID"
            ref="xandrIDInput"
            v-model="advertiserData.xandrAdvertiserId"
            type="text"
            aria-describedby="emailHelp"
            @input="validateAdvertiserXandrId"
          />
        </div>
        <div v-if="isTemplateIdEnabled" class="form-group">
          <label for="templateId">Template ID</label>
          <input
            id="templateId"
            ref="templateIdInput"
            v-model="advertiserData.templateId"
            type="number"
          />
        </div>
        <div class="form-group form-check">
          <label class="form-check-label" for="customReportsEnabled">Custom Reports</label>
          <k-checkbox
            id="customReportsEnabled"
            :checked="advertiserData.customReportsEnabled"
            name="advertiserData.customReportsEnabled"
            @change="setCustomReportsEnabled"
            @selected="setCustomReportsEnabled"
          />
        </div>
        <div class="form-group" style="text-align: right; float:right; display: contents;">
          <div class="actions-btn">
            <k-button :size="3" label="SAVE" class="mr-20" @click="saveAdvertiser" />
            <k-button :size="3" label="CLOSE" @click="close" />
          </div>
        </div>
      </form>
    </div>
    <div v-if="showAssignUserScreen && !loading" class="modal-body wiz-btn-wide">
      <div>
        <p class="sm2">Select user to assign</p>
      </div>
      <div class="table-wrap">
        <div>
          <table class="tbl tbl-export">
            <tbody>
              <tr class="no-bg no-bg-no-pg mg-tp-20">
                <td colspan="2" align="right">
                  <div class="form-group">
                    <k-select
                      :options="sortedUsers"
                      label-key="displayUserName"
                      :multiple="false"
                      :value="selectedUserId"
                      :searchable="true"
                      label="Please select user"
                      @input="onUserSelect"
                    />
                  </div>
                </td>
                <td align="right">
                  <div class="actions-btn">
                    <k-button :size="3" label="APPLY" class="wide-btn" @click="assignUser()" />
                  </div>
                </td>
              </tr>
              <tr class="no-bg no-bg-no-pg">
                <td colspan="2" align="right">
                  &nbsp;
                </td>
                <td align="right">
                  <div
                    v-if="hasPermission(E_USER, PRIVILEGE_TO_CREATE)"
                    class="actions-btn mg-bm-20"
                  >
                    <k-button :size="3" label="CREATE NEW USER" @click="showNewUserModal = true" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <p class="sm2">Users who have access</p>
      </div>
      <div class="form-group">
        <input v-model="searchText" type="text" aria-describedby="emailHelp" />
      </div>
      <div>
        <k-checkbox
          :checked="showInternalAdminsInList"
          label="Show Digital Remedy Users?"
          @change="setShowInternalAdminsInList"
          @selected="setShowInternalAdminsInList"
        />
      </div>
      <div class="table-wrap">
        <div class="table-scroll">
          <table v-if="filteredUserList.length && !loadingUserList" class="tbl tbl-export">
            <thead>
              <tr>
                <th>User</th>
                <th>Organization</th>
                <th>Role</th>
                <th>Invite Accepted</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(apl, apli) in filteredUserList" :key="apli" class="sub-row">
                <td>{{ apl.displayUserName }}</td>
                <td>{{ apl.organizationName }}</td>
                <td>{{ apl.role ? apl.role.name : '' }}</td>
                <td>{{ apl.inviteAccepted ? '✓' : '𐄂' }}</td>
                <td class="action-column">
                  <span class="intip4" data-tool-tip="Remove User">
                    <font-awesome-icon
                      v-if="isShowDeleteBtn(apl)"
                      :icon="['fas', 'trash']"
                      @click="removeUserAssignment(apl.id)"
                    />
                  </span>
                  <span
                    v-if="!apl.inviteAccepted"
                    class="intip4 re-invite-container"
                    data-tool-tip="Re-Invite User"
                  >
                    <font-awesome-icon :icon="['fas', 'envelope']" @click="reInviteUser(apl)" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else-if="!filteredUserList.length && !loadingUserList" class="txt-align-center">
            No data is available to show
          </div>
          <b-loading-spinner v-else-if="loadingUserList" class="txt-center" />
        </div>
        <div class="form-group" style="text-align: right; float:right; display: contents;">
          <div class="actions-btn mg-tp-20 mb-0">
            <k-button :size="3" label="CLOSE" @click="close" />
          </div>
        </div>
      </div>
    </div>
    <b-loading-spinner v-if="loading" class="txt-center" />
    <add-user
      v-if="showNewUserModal"
      :account-ref="createdAccount"
      :advertiser-ref="createdAdvertiser"
      :add-user-for="addUserFor"
      @close="handleUserUpdates"
    />
    <ConfirmModal
      v-if="showConfirmBoxModal"
      :type="confirmationObj.type"
      @cancel="showConfirmBoxModal = false"
      @remove="onClickConfirmSubmit"
    />
    <ConfirmModalReInvite
      v-if="showConfirmBoxModalRI"
      :email="confirmationReInviteObj.email"
      @cancel="showConfirmBoxModalRI = false"
      @send="onClickConfirmReInviteSubmit"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import usersApi from 'adready-api/api/user';
import { get } from 'vuex-pathify';
import { PRIVILEGE_TO_CREATE, E_USER } from 'adready-api/auth-constant';
import organizationApi from 'adready-api/api/organization';
import { Chrome } from 'vue-color';
import KSelect from '@/adready-vue/components/elements/k-select.vue';
import { validateStringWithRegex } from '@/helpers/global/misc-helpers';
import {
  TEMPLATE_ID_ENABLED_FOR_ACCOUNTS,
  ACC_FQDN_VALIDATION_REGEX,
  ACC_SUBDOMAIN_VALIDATION_REGEX,
} from '@/constant';
import advertiserReportsAPI from '~/api/advertiser-reports';
import advertiserApi from '~/api/advertiser';
import whiteLabelDomainApi from '~/api/whitelabel-domain-api';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import userApi from '~/api/user';
import filesAPI from '~/api/files';
import ConfirmModal from '~/pages/ConfirmModal.vue';
import ConfirmModalReInvite from '~/pages/ConfirmModalReInvite.vue';
import defaultTheme from '~/plugins/Theme/defaultTheme';
// import BFaviconInput from '../elements/b-favicon-input.vue';
import { buildQueryString } from '~/helpers/global/url-helpers';
import KButton from '../elements/k-button.vue';

export default {
  name: 'AddAccountWizard',

  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    AddUser: () => import(/* webpackChunkName: "add-user" */ '~/components/admin/add-user.vue'),
    ConfirmModal,
    ConfirmModalReInvite,
    ChromePicker: Chrome,
    // BFaviconInput,
    KSelect,
    KButton,
  },

  mixins: [forklift, authzMixin],

  props: {
    accountRef: {
      required: false,
      default: null,
      type: Object,
    },
  },

  data() {
    const existingAccount = JSON.parse(JSON.stringify(this.accountRef));
    let gotoNewAccountScreen = true;
    let gotoNewAdvertiserScreen = false;

    if (existingAccount && existingAccount.id) {
      gotoNewAccountScreen = false;
      gotoNewAdvertiserScreen = true;
    }

    return {
      selectedParentId: null,
      isAccAlert: true,
      showPrimaryColorPicker: false,
      name: '',
      accountDomainOpt: 'accountDomainUrl',
      loading: false,
      showNewAccountScreen: gotoNewAccountScreen,
      showNewAdvertiserScreen: gotoNewAdvertiserScreen,
      showAssignUserScreen: false,
      showInternalAdminsInList: false,
      loadingUserList: false,
      userList: [],
      selectedUserId: null,
      showNewUserModal: false,
      E_USER,
      errorMsg: null,
      errors: [],
      PRIVILEGE_TO_CREATE,
      accountData: {
        active: true,
        organization: {
          whitelabelEnabled: false,
          emailBranding: false,
          theme: {},
          subdomainEnabled: false,
          subdomainConfig: {},
        },
        parentId: null,
      },
      whiteLabelDomainData: {
        appType: 'APP',
        domainName: '',
        domainType: '',
      },
      createdAccount: existingAccount || {},
      advertiserData: {},
      createdAdvertiser: {},
      showConfirmBoxModal: false,
      showConfirmBoxModalRI: false,
      confirmationObj: null,
      confirmationReInviteObj: null,
      showMsg: null,
      searchText: '',
      dragging: false,
      fileToUpload: null,
      appConsoleSquareLogoFile: null,
      favIconLogoFile: null,
      appConsoleLogoFile: null,
      fileError: {},
      uploadPercentage: {},
      buttonTextOptions: [
        { id: 'dark', name: 'Dark' },
        { id: 'light', name: 'Light' },
      ],
      applicationOptions: ['APP', 'FLIP'],
    };
  },

  computed: {
    allAccounts: get('common/accounts'),
    allUsers: get('common/allUsers'),
    allOrganizations: get('common/allOrganizations'),
    userAccounts: get('common/userAccounts'),

    /**
     * for Internal Admin return all the accounts other wise return user's accounts.
     */
    allUserAccounts() {
      if (this.isInternalAdmin) {
        return this.allAccounts;
      }
      return this.userAccounts;
    },
    canDragDrop() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FileReader' in window
      );
    },
    primaryColor() {
      return this.accountData?.organization?.theme?.primaryColor || '';
    },
    overText() {
      return this.accountData?.organization?.theme?.buttonText === 'light' ? '#ffffff' : '#303335';
    },
    buttonText() {
      return this.accountData?.organization?.theme?.buttonText || this.buttonTextOptions[0].id;
    },
    applicationOption() {
      return this.whiteLabelDomainData.appType || '';
    },
    whitelabelEnabled() {
      return this.accountData?.organization?.whitelabelEnabled || false;
    },
    buttonPreviewStyles() {
      const primaryColor = this.primaryColor || defaultTheme.primaryColor;
      const { overText } = this;

      return `background-color: ${primaryColor}; color: ${overText} !important;`;
    },
    allAdvertisers() {
      const allAdv = [];
      this.allAccounts.forEach((aa) => {
        aa.advertisers.forEach((adv) => {
          allAdv.push(adv);
        });
      });
      return allAdv;
    },
    filteredUserList() {
      const allowedRoles = ['INTERNAL_ADMIN', 'INTERNAL_SUB_ADMIN'];
      let filteredUsers;
      if (this.showInternalAdminsInList) {
        filteredUsers = this.userList;
      } else {
        filteredUsers = this.userList.filter((u) => u.role && !allowedRoles.includes(u.role.name));
      }
      if (!isBlank(this.searchText)) {
        filteredUsers = filteredUsers.filter((u) =>
          u.userSearchText.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
      return filteredUsers;
    },

    sortedUsers() {
      if (isBlank(this.allUsers)) {
        return [];
      }
      const filteredUsers = [];
      filteredUsers.push(...this.fetchAccountUsers());
      filteredUsers.push(...this.fetchAdvertiserUsers());

      return commonHelper.caseInsensitiveSort([].concat(filteredUsers), 'displayUserName');
    },
    addUserFor() {
      return this.showAccountUserScreen ? 'account' : 'advertiser';
    },
    // error() {
    //   if (!isBlank(this.errors)) {
    //     const idElement = document.getElementById('errorContainer');
    //     if (idElement) {
    //       idElement.style.display = 'block';
    //     }
    //     return this.errorString();
    //   }
    //   return null;
    // },
    msg() {
      if (!isBlank(this.showMsg)) {
        return this.showMsg;
      }
      return null;
    },
    title() {
      let title = '';
      if (this.showNewAccountScreen) {
        title = 'New Account';
      } else if (this.showNewAdvertiserScreen) {
        title = 'New Advertiser';
      } else {
        title = 'Assign User';
      }
      return title;
    },
    sortedAccounts() {
      const accounts = this.allUserAccounts;
      if (isBlank(accounts)) {
        return [];
      }
      const sortedAccounts = commonHelper.caseInsensitiveSort([].concat(accounts), 'name');
      return sortedAccounts.filter((u) => !isBlank(u.name));
    },
    accountAdvertisers() {
      // Find the account that matches this.accountData.name
      const matchingAccount = this.allAccounts.find(
        // The createdAccount object may contain the name property if its an existing account
        (account) => account.name === this.createdAccount?.name
      );
      // If a matching account is found, filter its advertisers; otherwise, return an empty array
      return matchingAccount ? matchingAccount.advertisers : [];
    },
    advertiserExistInAccount() {
      return this.accountAdvertisers.filter(
        (advertiser) =>
          !isBlank(advertiser.name) &&
          advertiser.name.toLowerCase() === this.advertiserData.name.toLowerCase()
      );
    },
    matchingAdvertisers() {
      const { name } = this.advertiserData;
      if (isBlank(name)) {
        return [];
      }
      const searchTerm = name.toLowerCase();
      const advertisers = this.accountAdvertisers;
      return advertisers.filter(
        (advertiser) =>
          !isBlank(advertiser.name) && advertiser.name.toLowerCase().includes(searchTerm)
      );
    },
    isTemplateIdEnabled() {
      return TEMPLATE_ID_ENABLED_FOR_ACCOUNTS.includes(this.createdAccount.id);
    },
  },

  async mounted() {
    try {
      await this.loadAllUsers();
      await this.loadAllOrganizations();
    } catch (err) {
      console.error('error in mounting add-account-wizard ->', err);
    }
  },

  methods: {
    onParentIdSelect(parentId) {
      this.selectedParentId = parentId;
      this.validateParentId();
    },
    handleAccountDomainRadioChange() {
      this.accountDomainOpt = 'accountDomainUrl';
      this.whiteLabelDomainData.domainType = 'STD_SUBDOMAIN';
      this.$refs.customDomainUrlInput.classList.remove('errorBox');
    },
    handleCustomDomainRadioChange() {
      this.accountDomainOpt = 'customDomainUrl';
      this.whiteLabelDomainData.domainType = 'FQDN';
      this.$refs.accountDomainUrlInput.classList.remove('errorBox');
    },
    handleFaviconUrlChange(val) {
      if (!this.accountData.organization.subdomainConfig) {
        Vue.set(this.accountData.organization, 'subdomainConfig', {});
      }
      if (!this.accountData?.organization?.subdomainConfig?.faviconUrl) {
        Vue.set(this.accountData.organization.subdomainConfig, 'faviconUrl', val);
      }
      this.accountData.organization.subdomainConfig.faviconUrl = val;
    },
    handleAccountDomainUrlChange(val) {
      const v = (val?.srcElement || val?.target)?.value;
      Vue.set(this.whiteLabelDomainData, 'domainName', v);
      Vue.set(this.whiteLabelDomainData, 'domainType', 'STD_SUBDOMAIN');
      this.whiteLabelDomainData.domainName = v;
      this.whiteLabelDomainData.domainType = 'STD_SUBDOMAIN';
    },
    handleAccountCustomUrlChange(val) {
      const v = (val?.srcElement || val?.target)?.value;
      Vue.set(this.whiteLabelDomainData, 'domainName', v);
      Vue.set(this.whiteLabelDomainData, 'domainType', 'FQDN');
      this.whiteLabelDomainData.domainName = v;
      this.whiteLabelDomainData.domainType = 'FQDN';
    },
    setBrandingDomain(val) {
      if (!this.accountData.organization) {
        Vue.set(this.accountData, 'organization', {
          subdomainEnabled: false,
          subdomainConfig: {},
        });
      }
      if (!this.accountData.organization?.subdomainConfig) {
        Vue.set(this.accountData.organization, 'subdomainConfig', {});
      }
      if (!this.accountData.organization?.subdomainConfig?.accountDomainUrl) {
        Vue.set(
          this.accountData.organization.subdomainConfig,
          'accountDomainUrl',
          this.accountData.organization.accountDomainUrl
        );
      }
      if (!this.accountData.organization?.subdomainConfig?.faviconUrl) {
        Vue.set(
          this.accountData.organization.subdomainConfig,
          'faviconUrl',
          this.accountData.organization.faviconUrl ? this.accountData.organization.faviconUrl : ''
        );
      }
      // when user select enable whitelabel domain need to set app type as APP
      this.handleApplicationOptionChange('APP');
      this.accountData.organization.subdomainEnabled = val.checked;
    },
    isShowDeleteBtn(user) {
      const orgType = this.getSelectedOrgType(user);
      return (
        (orgType === 'dr' && user.role.name === 'INTERNAL_USER') ||
        (orgType === 'account' && user.role.name === 'EXTERNAL_USER')
      );
    },

    reInviteUser(user) {
      this.showMsg = null;
      this.onClickConfirmReInvite({
        email: user.email,
        value: user,
      });
    },
    onClickConfirmReInvite(item) {
      this.confirmationReInviteObj = item;
      this.showConfirmBoxModalRI = true;
    },
    onClickConfirmReInviteSubmit() {
      this.sendInvite(this.confirmationReInviteObj.value);
      this.showConfirmBoxModalRI = false;
    },
    async sendInvite(user) {
      try {
        // uncomment at the time of commit
        await usersApi.sendInvite(user.id);
        this.showMsg = 'Email invitation  sent.';
      } catch (err) {
        this.showMsg = 'Unable to send an invitation email.';
        console.error(`error sending invite to ${user.email} (id: ${user.id}) user ->`, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    handleFileReset(fileType) {
      this[fileType] = null;
    },
    onFileChange(ev, fileType) {
      const validImageTypes =
        fileType === 'favIconLogoFile'
          ? ['image/png', 'image/x-icon', 'image/vnd.microsoft.icon']
          : ['image/png'];

      const files = ev.target.files || (this.canDragDrop ? ev.dataTransfer.files : []);
      if (files.length === 0) {
        return;
      }

      const fileToUpload = files[0];
      if (fileToUpload) {
        if (validImageTypes.includes(fileToUpload?.type)) {
          this.fileError[fileType] = '';
          // handle file upload
          this[fileType] = { file: fileToUpload, src: URL.createObjectURL(fileToUpload) };
          if (ev?.target?.files?.length) {
            ev.target.value = '';
          }
        } else {
          this.fileError[fileType] =
            fileType === 'favIconLogoFile'
              ? 'Only png and ICO files are supported'
              : 'Only png files are supported';
        }
      }
      this.fileError = { ...this.fileError };
    },
    handleColorChange(e, key) {
      if (!this.accountData.organization.theme) {
        Vue.set(this.accountData.organization, 'theme', {});
      }
      if (!this.accountData?.organization?.theme?.[key]) {
        Vue.set(this.accountData.organization.theme, key, '');
      }
      this.accountData.organization.theme[key] = e.hex;
    },
    handleOverTextChange(color) {
      if (!this.accountData.organization.theme) {
        Vue.set(this.accountData.organization, 'theme', {});
      }
      if (!this.accountData?.organization?.theme?.buttonText) {
        Vue.set(this.accountData.organization.theme, 'buttonText', color);
      }
      this.accountData.organization.theme.buttonText = color;
    },
    handleApplicationOptionChange(application) {
      if (!this.whiteLabelDomainData?.appType) {
        Vue.set(this.whiteLabelDomainData, 'appType', application);
      }
      this.whiteLabelDomainData.appType = application;
    },
    setColorEnabled(val) {
      if (!this.accountData.organization) {
        Vue.set(this.accountData, 'organization', { whitelabelEnabled: false, theme: {} });
      }
      if (!this.accountData.organization?.theme) {
        Vue.set(this.accountData.organization, 'theme', {});
      }
      if (!this.accountData.organization?.theme?.buttonText) {
        Vue.set(this.accountData.organization.theme, 'buttonText', this.buttonTextOptions[0].id);
      }
      this.accountData.organization.whitelabelEnabled = val.checked;
    },
    setEmailBranding(val) {
      this.accountData.organization.emailBranding = val.checked;
    },
    fetchAccountUsers() {
      if (isBlank(this.allUsers)) {
        return [];
      }
      const assignedUsers = isBlank(this.userList) ? [] : this.userList.map((u) => u.id);
      const users = JSON.parse(JSON.stringify(this.allUsers));
      users.forEach((u) => {
        u.displayUserName = `${u.userName || ''} (${u.email})`;
      });
      const allowedRoles = ['EXTERNAL_SUB_ADMIN'];
      const allowedOrgs = [1, this.createdAccount.organizationId];
      return users.filter(
        (u) =>
          u.role &&
          allowedRoles.includes(u.role.name) &&
          allowedOrgs.includes(u.organizationId) &&
          !assignedUsers.includes(u.id)
      );
    },
    fetchAdvertiserUsers() {
      if (isBlank(this.allUsers)) {
        return [];
      }
      const assignedUsers = isBlank(this.userList) ? [] : this.userList.map((u) => u.id);
      const users = JSON.parse(JSON.stringify(this.allUsers));
      users.forEach((u) => {
        u.displayUserName = `${u.userName || ''} (${u.email})`;
      });
      const allowedRoles = ['INTERNAL_USER', 'EXTERNAL_USER'];
      const allowedOrgs = [
        1,
        this.createdAccount.organizationId,
        this.createdAdvertiser.organizationId,
      ];
      return users.filter(
        (u) =>
          u.role &&
          allowedRoles.includes(u.role.name) &&
          allowedOrgs.includes(u.organizationId) &&
          !assignedUsers.includes(u.id)
      );
    },
    async loadAccountAdvertiserUsers() {
      this.errors = [];
      this.showMsg = null;
      this.loadingUserList = true;
      this.userList = [];
      const accountAdvertiserUsers = [];
      const acctUsers = await userApi.listUsers(this.createdAccount.id);
      try {
        if (acctUsers) {
          acctUsers.forEach((u) => {
            const org = this.allOrganizations.find((o) => o.id === u.organizationId);
            if (org) {
              u.organizationName = org.name;
            }
            u.displayUserName = `${u.userName || ''} (${u.email})`;
            u.userSearchText = `${u.userName || ''} ${u.email || ''} ${u.organizationName || ''} ${
              u.role ? u.role.name : ''
            }`;
          });
          accountAdvertiserUsers.push(...acctUsers);
        }
      } catch (err) {
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }

      const assignedUsers = isBlank(accountAdvertiserUsers)
        ? []
        : accountAdvertiserUsers.map((u) => u.id);

      const users = await userApi.listAdvertiserUsers(this.createdAdvertiser.id);
      try {
        if (users) {
          users.forEach((u) => {
            const org = this.allOrganizations.find((o) => o.id === u.organizationId);
            if (org) {
              u.organizationName = org.name;
            }
            u.displayUserName = `${u.userName || ''} (${u.email})`;
            u.userSearchText = `${u.userName || ''} ${u.email || ''} ${u.organizationName || ''} ${
              u.role ? u.role.name : ''
            }`;
          });
          accountAdvertiserUsers.push(...users.filter((u) => !assignedUsers.includes(u.id)));
        }
      } catch (err) {
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }

      const sortedList = commonHelper.caseInsensitiveSort(
        [].concat(accountAdvertiserUsers),
        'displayUserName'
      );

      this.userList = sortedList;
      this.loadingUserList = false;
    },
    assignUser() {
      if (!this.selectedUserId) {
        return;
      }
      const selectedUser = this.allUsers.find((item) => item.id === this.selectedUserId);
      if (!selectedUser) {
        return;
      }
      const userOrgType = this.getSelectedOrgType(selectedUser);
      const userRole = selectedUser?.role?.name || '';

      if (userOrgType === 'dr') {
        if (userRole === 'INTERNAL_USER') {
          this.assignUserToAdvertiser();
        }
      } else if (userOrgType === 'account') {
        if (userRole === 'EXTERNAL_SUB_ADMIN') {
          this.assignUserToAccount();
        } else if (userRole === 'EXTERNAL_USER') {
          this.assignUserToAdvertiser();
        }
      } else if (userOrgType === 'advertiser') {
        if (userRole === 'EXTERNAL_USER') {
          this.assignUserToAdvertiser();
        }
      }
    },
    getSelectedOrgType(user) {
      let orgType = 'unknown';
      const orgId = user.organizationId;
      if (orgId === 1) {
        orgType = 'dr';
      }

      if (this.createdAccount && this.createdAccount?.organizationId === orgId) {
        orgType = 'account';
      } else if (this.createdAdvertiser && this.createdAdvertiser.organizationId === orgId) {
        orgType = 'advertiser';
      }

      return orgType;
    },
    assignUserToAccount() {
      if (!this.selectedUserId) {
        return;
      }

      const userAccount = [{ userId: this.selectedUserId, accountId: this.createdAccount.id }];
      usersApi.mapAccounts(this.selectedUserId, userAccount).then(() => {
        const error = { name: 'userSuccess', message: 'User assigned successfully' };
        if (!this.errors.find((item) => item.name === 'userSuccess')) this.errors.push(error);

        this.selectedUserId = null;
        this.loadAccountAdvertiserUsers();
      });

      this.selectedUserId = null;
    },
    removeUserAssignment(userIdToRemove) {
      const selectedUser = this.allUsers.find((item) => item.id === userIdToRemove);
      if (!selectedUser) {
        return;
      }
      const userOrgType = this.getSelectedOrgType(selectedUser);
      const userRole = selectedUser?.role?.name || '';

      if (userOrgType === 'dr') {
        if (userRole === 'INTERNAL_USER') {
          this.removeUserFromAdvertiser(userIdToRemove);
        }
      } else if (userOrgType === 'account') {
        if (userRole === 'EXTERNAL_SUB_ADMIN') {
          this.removeUserFromAccount(userIdToRemove);
        } else if (userRole === 'EXTERNAL_USER') {
          this.removeUserFromAdvertiser(userIdToRemove);
        }
      } else if (userOrgType === 'advertiser') {
        if (userRole === 'EXTERNAL_USER') {
          this.removeUserFromAdvertiser(userIdToRemove);
        }
      }
    },
    removeUserFromAccount(userIdToRemove) {
      this.onClickConfirm({
        type: 'userAccount',
        value: { accountId: this.createdAccount.id, userId: userIdToRemove },
      });
    },
    assignUserToAdvertiser() {
      if (!this.selectedUserId) {
        return;
      }

      const userAdvertiser = [
        {
          userId: this.selectedUserId,
          advertiserId: this.createdAdvertiser.id,
        },
      ];
      usersApi.mapAdvertisers(this.selectedUserId, userAdvertiser).then(() => {
        const error = { name: 'userSuccess', message: 'User assigned successfully' };
        if (!this.errors.find((item) => item.name === 'userSuccess')) this.errors.push(error);

        this.selectedUserId = null;
        this.loadAccountAdvertiserUsers();
      });

      this.selectedUserId = null;
    },
    removeUserFromAdvertiser(userIdToRemove) {
      this.onClickConfirm({
        type: 'userAdvertiser',
        value: { advertiserId: this.createdAdvertiser.id, userId: userIdToRemove },
      });
    },
    onClickConfirm(item) {
      this.confirmationObj = item;
      this.showConfirmBoxModal = true;
    },
    onClickConfirmSubmit() {
      if (this.confirmationObj.type === 'userAccount') {
        this.onDeleteUserAccount(this.confirmationObj.value);
      } else if (this.confirmationObj.type === 'userAdvertiser') {
        this.onDeleteAdvertiser(this.confirmationObj.value);
      }
      this.showConfirmBoxModal = false;
    },
    onDeleteUserAccount(account) {
      usersApi.removeAccount(account.userId, account).then(() => {
        this.loadAccountAdvertiserUsers();
      });
    },
    onDeleteAdvertiser(advertiser) {
      usersApi.removeAdvertiser(advertiser.userId, advertiser).then(() => {
        this.loadAccountAdvertiserUsers();
      });
    },
    handleUserUpdates() {
      this.showNewUserModal = false;
      this.loadAccountAdvertiserUsers();
    },
    setActive(val) {
      this.accountData.active = val.checked;
    },
    setShowInternalAdminsInList(val) {
      this.showInternalAdminsInList = val.checked;
    },
    onUserSelect(userId) {
      this.errors = [];
      this.showMsg = null;
      this.selectedUserId = userId;
    },
    // clearValidationErrors() {
    // this.errorMsg = null;
    // this.showMsg = null;
    // this.isAccAlert = true;
    // if (this.$refs.nameInput) this.$refs.nameInput.classList.remove('errorBox');
    // if (this.$refs.abbInput) this.$refs.abbInput.classList.remove('errorBox');
    // if (this.$refs.prefixInput) this.$refs.prefixInput.classList.remove('errorBox');
    // if (this.$refs.countryCodeInput) this.$refs.countryCodeInput.classList.remove('errorBox');
    // if (this.$refs.currencyInput) this.$refs.currencyInput.classList.remove('errorBox');
    // if (this.$refs.advnameInput) this.$refs.advnameInput.classList.remove('errorBox');
    // if (this.$refs.xandrIDInput) this.$refs.xandrIDInput.classList.remove('errorBox');
    // if (this.$refs.accountDomainUrlInput)
    //   this.$refs.accountDomainUrlInput.classList.remove('errorBox');
    // },
    clearNameError() {
      this.errors = this.errors.filter((item) => item.name !== 'accountName');
      if (this.$refs.nameInput) this.$refs.nameInput.classList.remove('errorBox');
    },
    clearAbbError() {
      this.errors = this.errors.filter((item) => item.name !== 'accountAbb');
      if (this.$refs.abbInput) this.$refs.abbInput.classList.remove('errorBox');
    },
    clearPrefixError() {
      this.errors = this.errors.filter((item) => item.name !== 'accountPrefix');
      if (this.$refs.prefixInput) this.$refs.prefixInput.classList.remove('errorBox');
    },
    clearCountryCodeError() {
      this.errors = this.errors.filter((item) => item.name !== 'countryCode');
      if (this.$refs.countryCodeInput) this.$refs.countryCodeInput.classList.remove('errorBox');
    },
    clearCurrencyError() {
      this.errors = this.errors.filter((item) => item.name !== 'currency');
      if (this.$refs.currencyInput) this.$refs.currencyInput.classList.remove('errorBox');
    },
    clearParentIdError() {
      this.isAccAlert = true;
    },
    clearAdvnameError() {
      this.errors = this.errors.filter((item) => item.name !== 'advName');
      if (this.$refs.advnameInput) this.$refs.advnameInput.classList.remove('errorBox');
    },
    clearXandrIdError() {
      this.errors = this.errors.filter((item) => item.name !== 'xandrId');
      if (this.$refs.xandrIDInput) this.$refs.xandrIDInput.classList.remove('errorBox');
    },
    clearAccountDomainUrlError() {
      this.errors = this.errors.filter((item) => item.name !== 'domainUrl');
      if (this.$refs.accountDomainUrlInput)
        this.$refs.accountDomainUrlInput.classList.remove('errorBox');
    },
    clearMsg() {
      this.showMsg = null;
    },
    getErrors(errorField) {
      const errSet = new Set(this.errors);
      const errArr = Array.from(errSet);
      const errItem = errArr.find((item) => item.name === errorField);
      // if (!isBlank(this.errors)) {
      if (errItem) {
        const idElement = document.getElementById('errorContainer');
        if (idElement) {
          idElement.style.display = 'block';
        }
        const errMessage = errItem.message ? errItem.message : null;
        return errMessage;
        // return this.errorString(errorField);
      }
      return null;
    },
    // errorString(errorField) {
    //   console.log('errorString field: ', errorField);
    //   // const errSet = new Set(this.errors);
    //   // const errArr = Array.from(errSet);
    //   // const errString = errArr.map((item) => item.message);
    //   // const errItem = errArr.find((item) => item.name === errorField);
    //   console.log('errItem: ', errItem);
    //   const errMessage = errItem.message ? errItem.message : null;

    //   // return errString.join(', ');
    //   return errMessage;
    // },
    async validateAccountName() {
      this.clearNameError();

      if (isBlank(this.accountData.name)) {
        this.$refs.nameInput.focus();
        this.$refs.nameInput.classList.add('errorBox');

        const error = { name: 'accountName', message: 'Please enter name' };
        if (!this.errors.find((item) => item.name === 'accountName')) this.errors.push(error);

        return false;
      }

      if (!validateStringWithRegex(this.accountData.name)) {
        this.$refs.nameInput.focus();
        this.$refs.nameInput.classList.add('errorBox');

        const error = { name: 'accountName', message: 'Please enter a valid Name' };
        if (!this.errors.find((item) => item.name === 'accountName')) this.errors.push(error);

        return false;
      }

      const payload = {
        name: this.accountData.name,
      };
      const param = buildQueryString(payload);
      const response = await advertiserReportsAPI.isNameExist(param);
      const accountFoundWithSameName = response.data;
      if (accountFoundWithSameName) {
        const error = {
          name: 'accountName',
          message: `Account already exists with ${this.accountData.name} name`,
        };
        if (!this.errors.find((item) => item.name === 'accountName')) this.errors.push(error);

        return false;
      }

      return true;
    },
    async validateAccountAbbreviation() {
      this.clearAbbError();

      if (isBlank(this.accountData.abbreviation)) {
        this.$refs.abbInput.focus();
        this.$refs.abbInput.classList.add('errorBox');

        const error = { name: 'accountAbb', message: 'Please enter abbreviation' };
        if (!this.errors.find((item) => item.name === 'accountAbb')) this.errors.push(error);

        return false;
      }

      if (this.accountData.abbreviation.length !== 3) {
        this.$refs.abbInput.focus();
        this.$refs.abbInput.classList.add('errorBox');

        const error = { name: 'accountAbb', message: 'Abbreviation must be 3 characters long' };
        if (!this.errors.find((item) => item.name === 'accountAbb')) this.errors.push(error);

        return false;
      }

      const payload = {
        abbreviation: this.accountData.abbreviation,
      };
      const param = buildQueryString(payload);
      const response = await advertiserReportsAPI.isAbbreviationExist(param);
      const accountFoundWithSameAbbreviation = response.data;
      if (accountFoundWithSameAbbreviation) {
        const error = {
          name: 'accountAbb',
          message: `Account already exists with ${this.accountData.abbreviation} abbreviation`,
        };
        if (!this.errors.find((item) => item.name === 'accountAbb')) this.errors.push(error);

        return false;
      }

      return true;
    },
    async validateAccountPrefix() {
      this.clearPrefixError();

      if (isBlank(this.accountData.prefix)) {
        this.$refs.prefixInput.focus();
        this.$refs.prefixInput.classList.add('errorBox');

        const error = { name: 'accountPrefix', message: 'Please enter prefix' };
        if (!this.errors.find((item) => item.name === 'accountPrefix')) this.errors.push(error);

        return false;
      }
      if (this.accountData.prefix.length !== 2) {
        this.$refs.prefixInput.focus();
        this.$refs.prefixInput.classList.add('errorBox');

        const error = { name: 'accountPrefix', message: 'Prefix must be 2 characters long' };
        if (!this.errors.find((item) => item.name === 'accountPrefix')) this.errors.push(error);

        return false;
      }

      const payload = {
        prefix: this.accountData.prefix,
      };
      const param = buildQueryString(payload);
      const response = await advertiserReportsAPI.isPrefixExist(param);
      const accountFoundWithSamePrefix = response.data;
      if (accountFoundWithSamePrefix) {
        const error = {
          name: 'accountPrefix',
          message: `Account already exists with ${this.accountData.prefix} prefix`,
        };
        if (!this.errors.find((item) => item.name === 'accountPrefix')) this.errors.push(error);

        return false;
      }

      return true;
    },
    validateAccountCountryCode() {
      this.clearCountryCodeError();

      if (isBlank(this.accountData.countryCode)) {
        this.$refs.countryCodeInput.focus();
        this.$refs.countryCodeInput.classList.add('errorBox');

        const error = { name: 'countryCode', message: 'Please enter country code' };
        if (!this.errors.find((item) => item.name === 'countryCode')) this.errors.push(error);

        return false;
      }
      if (this.accountData.countryCode.length !== 2) {
        this.$refs.countryCodeInput.focus();
        this.$refs.countryCodeInput.classList.add('errorBox');

        const error = {
          name: 'countryCode',
          message: 'Country code must be 2 characters long',
        };
        if (!this.errors.find((item) => item.name === 'countryCode')) this.errors.push(error);

        return false;
      }

      return true;
    },
    validateAccountCurrency() {
      this.clearCurrencyError();

      if (isBlank(this.accountData.currency)) {
        this.$refs.currencyInput.focus();
        this.$refs.currencyInput.classList.add('errorBox');

        const error = { name: 'currency', message: 'Please enter currency' };
        if (!this.errors.find((item) => item.name === 'currency')) this.errors.push(error);

        return false;
      }
      if (this.accountData.currency.length !== 3) {
        this.$refs.currencyInput.focus();
        this.$refs.currencyInput.classList.add('errorBox');

        const error = { name: 'currency', message: 'Currency must be 3 characters long' };
        if (!this.errors.find((item) => item.name === 'currency')) this.errors.push(error);

        return false;
      }

      return true;
    },
    validateFile() {
      if (this.fileError) {
        return false;
      }

      return true;
    },
    validateApplicationSelect() {
      if (
        this.accountData.organization.subdomainEnabled &&
        isBlank(this.whiteLabelDomainData.appType)
      ) {
        const error = { name: 'applicationSelect', message: 'Please select App Type' };
        if (!this.errors.find((item) => item.name === 'applicationSelect')) this.errors.push(error);

        return false;
      }
      return true;
    },
    validateAccountSubdomainUrl(val) {
      const idElement = document.getElementById('errorContainer');
      if (!this.accountData.organization.subdomainEnabled) {
        return true;
      }
      if (this.accountDomainOpt !== 'accountDomainUrl') {
        return true;
      }
      const v = (val?.srcElement || val?.target)?.value || this.whiteLabelDomainData.domainName;
      if (isBlank(v)) {
        this.$refs?.accountDomainUrlInput?.focus?.();
        this.$refs?.accountDomainUrlInput?.classList?.add('errorBox');
        idElement.style.display = 'block';

        const error = { name: 'accountDomainUrl', message: 'Please enter an account subdomain' };
        if (!this.errors.find((item) => item.name === 'accountDomainUrl')) this.errors.push(error);

        return false;
      }
      if (!validateStringWithRegex(v, ACC_SUBDOMAIN_VALIDATION_REGEX)) {
        this.$refs?.accountDomainUrlInput?.focus?.();
        this.$refs?.accountDomainUrlInput?.classList?.add('errorBox');
        idElement.style.display = 'block';

        const error = {
          name: 'accountDomainUrl',
          message: 'Please enter a valid account subdomain',
        };
        if (!this.errors.find((item) => item.name === 'accountDomainUrl')) this.errors.push(error);

        return false;
      }
      if (idElement) {
        idElement.style.display = 'none';
      }
      this.$refs?.accountDomainUrlInput?.classList.remove('errorBox');
      return true;
    },
    validateAccountCustomUrl(val) {
      const idElement = document.getElementById('errorContainer');
      if (!this.accountData.organization.subdomainEnabled) {
        return true;
      }
      if (this.accountDomainOpt !== 'customDomainUrl') {
        return true;
      }
      const v = (val?.srcElement || val?.target)?.value || this.whiteLabelDomainData.domainName;
      if (
        this.accountDomainOpt === 'customDomainUrl' &&
        this.accountData.organization.subdomainEnabled &&
        isBlank(v)
      ) {
        this.$refs?.customDomainUrlInput?.focus?.();
        this.$refs?.customDomainUrlInput?.classList?.add('errorBox');
        idElement.style.display = 'block';

        const error = { name: 'customDomainUrl', message: 'Please enter account domain url' };
        if (!this.errors.find((item) => item.name === 'customDomainUrl')) this.errors.push(error);

        return false;
      }
      if (
        this.accountDomainOpt === 'customDomainUrl' &&
        this.accountData.organization.subdomainEnabled &&
        !validateStringWithRegex(v, ACC_FQDN_VALIDATION_REGEX)
      ) {
        this.$refs?.customDomainUrlInput?.focus?.();
        this.$refs?.customDomainUrlInput?.classList?.add('errorBox');
        idElement.style.display = 'block';

        const error = {
          name: 'customDomainUrl',
          message: 'Please enter a valid account domain url',
        };
        if (!this.errors.find((item) => item.name === 'customDomainUrl')) this.errors.push(error);

        return false;
      }
      if (idElement) {
        idElement.style.display = 'none';
      }
      this.$refs?.customDomainUrlInput?.classList?.remove('errorBox');
      return true;
    },
    validateParentId() {
      this.clearParentIdError();

      if (this.isInternalAdmin) {
        return true;
      }
      if (isBlank(this.accountData.parentId)) {
        this.isAccAlert = false;

        const error = { name: 'accountParentId', message: 'Please select parent account' };
        if (!this.errors.find((item) => item.name === 'accountParentId')) this.errors.push(error);

        return false;
      }
      return true;
    },
    validateAccountData() {
      this.clearMsg();

      let isValidated = true;

      if (!this.validateAccountName()) isValidated = false;
      if (!this.validateAccountAbbreviation()) isValidated = false;
      if (!this.validateAccountPrefix()) isValidated = false;
      if (!this.validateAccountCountryCode()) isValidated = false;
      if (!this.validateAccountCurrency()) isValidated = false;
      if (!this.validateAccountSubdomainUrl()) isValidated = false;
      if (!this.validateAccountCustomUrl()) isValidated = false;
      // if (!this.validateApplicationSelect()) isValidated = false;
      if (!this.validateParentId()) isValidated = false;

      return isValidated;
    },
    validateAdvertiserName() {
      this.clearAdvnameError();

      if (isBlank(this.advertiserData.name)) {
        this.$refs.advnameInput.focus();
        this.$refs.advnameInput.classList.add('errorBox');

        const error = { name: 'advName', message: 'Please enter advertiser name' };
        if (!this.errors.find((item) => item.name === 'advName')) this.errors.push(error);

        return false;
      }

      const advertiserFoundWithSameName =
        !this.createdAccount.name &&
        !isBlank(this.allAdvertisers) &&
        this.allAdvertisers.some(
          (advertiser) =>
            !isBlank(advertiser.name) &&
            !isBlank(this.advertiserData.name) &&
            advertiser.name.toUpperCase() === this.advertiserData.name.toUpperCase()
        );

      // Check if the 'advName' error already exists in the errors array to avoid duplication
      const advNameErrorExists = this.errors.some((error) => error.name === 'advName');

      const advNameError = {
        name: 'advName',
        message: `Advertiser already exists with ${this.advertiserData.name} name`,
      };

      // Determine the condition under which to add the advNameError
      const shouldAddAdvNameError =
        (!isBlank(this.advertiserExistInAccount) || advertiserFoundWithSameName) &&
        !advNameErrorExists;

      if (shouldAddAdvNameError) {
        this.errors.push(advNameError);
        return false;
      }

      return true;
    },
    validateAdvertiserXandrId() {
      this.clearXandrIdError();

      if (isBlank(this.advertiserData.xandrAdvertiserId)) {
        return true;
      }

      if (
        this.advertiserData.xandrAdvertiserId === '0' ||
        Number.isNaN(Number(this.advertiserData.xandrAdvertiserId))
      ) {
        this.$refs.xandrIDInput.focus();
        this.$refs.xandrIDInput.classList.add('errorBox');

        const error = { name: 'xandrId', message: 'Please enter valid Xandr ID' };
        if (!this.errors.find((item) => item.name === 'xandrId')) this.errors.push(error);

        return false;
      }

      const advertiserFoundWithSameXandrAdvertiserId =
        !isBlank(this.allAdvertisers) &&
        this.allAdvertisers.some(
          (advertiser) =>
            !isBlank(advertiser.xandrAdvertiserId) &&
            advertiser.xandrAdvertiserId.toString() === this.advertiserData.xandrAdvertiserId
        );

      if (advertiserFoundWithSameXandrAdvertiserId) {
        const error = {
          name: 'xandrId',
          message: `The ${this.advertiserData.xandrAdvertiserId} Xandr ID has been assigned to another advertiser`,
        };
        if (!this.errors.find((item) => item.name === 'xandrId')) this.errors.push(error);

        return false;
      }

      return true;
    },
    uploadProgress(progressEvent, fileType) {
      // eslint-disable-next-line radix
      this.uploadPercentage[fileType] = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },
    validateAdvertiserData() {
      // this.clearValidationErrors();
      this.clearAdvnameError();
      this.clearXandrIdError();

      if (!this.validateAdvertiserName() || !this.validateAdvertiserXandrId()) {
        return false;
      }

      return true;
    },
    async saveAccount() {
      const dataValidated = this.validateAccountData();

      if (!dataValidated) {
        return false;
      }
      if (this.whiteLabelDomainData.domainName) {
        const apiResponse = await whiteLabelDomainApi.getDomainName(
          this.whiteLabelDomainData.domainName
        );
        const { data: apiData } = apiResponse;
        if (apiData?.domainName) {
          const error = { name: 'whiteLabelDomain', message: 'Domain name already exists' };
          if (!this.errors.find((item) => item.name === 'whiteLabelDomain'))
            this.errors.push(error);

          if (this.accountDomainOpt === 'customDomainUrl') {
            this.$refs.customDomainUrlInput.focus();
            this.$refs.customDomainUrlInput.classList.add('errorBox');
          } else {
            this.$refs.accountDomainUrlInput.focus();
            this.$refs.accountDomainUrlInput.classList.add('errorBox');
          }
          return false;
        }
      }
      try {
        this.loading = true;
        const createdAccount = await accountApi.create(this.accountData);
        if (createdAccount?.organization?.id && this.whitelabelEnabled) {
          if (this.fileToUpload) {
            try {
              const formData = new FormData();
              formData.append('file', this.fileToUpload.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', createdAccount?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'fileToUpload'),
              };
              const uploadedFile = await filesAPI.uploadOrganizationLogo(
                createdAccount?.organization?.id,
                formData,
                config
              );
              createdAccount.organization.logoFile = uploadedFile;
              this.uploadPercentage.fileToUpload = 0;
            } catch (e) {
              console.error(e);
            }
          }
          if (this.appConsoleLogoFile) {
            try {
              const formData = new FormData();
              formData.append('file', this.appConsoleLogoFile.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', createdAccount?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'appConsoleLogoFile'),
              };
              const uploadedFile = await filesAPI.uploadOrganizationLogo(
                createdAccount?.organization?.id,
                formData,
                config
              );
              createdAccount.organization.appConsoleLogoFile = uploadedFile;
              this.uploadPercentage.appConsoleLogoFile = 0;
            } catch (e) {
              console.error(e);
            }
          }
          if (this.favIconLogoFile) {
            try {
              const formData = new FormData();
              formData.append('file', this.favIconLogoFile.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', createdAccount?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'favIconLogoFile'),
              };
              const uploadedFile = await filesAPI.uploadOrganizationLogo(
                createdAccount?.organization?.id,
                formData,
                config
              );
              createdAccount.organization.favIconLogoFile = uploadedFile;
              this.uploadPercentage.favIconLogoFile = 0;
            } catch (e) {
              console.error(e);
            }
          }
          if (this.appConsoleSquareLogoFile) {
            try {
              const formData = new FormData();
              formData.append('file', this.appConsoleSquareLogoFile.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', createdAccount?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'appConsoleSquareLogoFile'),
              };
              const uploadedFile = await filesAPI.uploadOrganizationLogo(
                createdAccount?.organization?.id,
                formData,
                config
              );
              createdAccount.organization.appConsoleSquareLogoFile = uploadedFile;
              this.uploadPercentage.appConsoleSquareLogoFile = 0;
              createdAccount.organization.emailBranding = this.createdAccount?.organization?.emailBranding;
            } catch (e) {
              console.error(e);
            }
          }
          await organizationApi.update(
            createdAccount?.organization?.id,
            createdAccount?.organization
          );
          if (this.accountData.organization.subdomainEnabled) {
            const data = {
              orgId: createdAccount?.organization?.id,
            };
            if (this.whiteLabelDomainData.appType) {
              data.appType = this.whiteLabelDomainData.appType;
            }
            if (this.whiteLabelDomainData.domainName) {
              data.domainName = this.whiteLabelDomainData.domainName;
            }
            if (this.whiteLabelDomainData.domainType) {
              data.domainType = this.whiteLabelDomainData.domainType;
            }
            await whiteLabelDomainApi.create({ acctId: createdAccount.id }, data);
          }
        }
        this.loadAccounts(true);
        this.loadAllOrganizations(true);
        this.createdAccount = createdAccount;
      } catch (err) {
        const error = { name: 'savingError', message: 'Failed while adding the account' };
        if (!this.errors.find((item) => item.name === 'savingError')) this.errors.push(error);

        console.error('error add account api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
        if (isBlank(this.errors)) {
          this.showNewAccountScreen = false;
          this.showNewAdvertiserScreen = true;
          this.showAssignUserScreen = false;
        }
      }
      return true;
    },
    async saveAdvertiser() {
      const dataValidated = this.validateAdvertiserData();
      if (!dataValidated) {
        return;
      }

      this.advertiserData.accountId = this.createdAccount.id;

      try {
        this.loading = true;
        const createdAdvertiser = await advertiserApi.createAdvertiser(this.advertiserData);
        this.createdAdvertiser = createdAdvertiser;
        this.loadAllOrganizations(true);
        this.loadAccountAdvertiserUsers();
      } catch (err) {
        const error = { name: 'savingError', message: 'Failed while adding the advertiser' };
        if (!this.errors.find((item) => item.name === 'savingError')) this.errors.push(error);

        console.error('error add advertiser api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
        if (isBlank(this.errors)) {
          this.showNewAccountScreen = false;
          this.showNewAdvertiserScreen = false;
          this.showAssignUserScreen = true;
          this.searchText = '';
        }
      }
    },
    close() {
      this.$emit('close', this.createdAccount);
    },
    setCustomReportsEnabled(val) {
      this.advertiserData.customReportsEnabled = val.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper-box .form-group .dropdown.b-select-dd {
  min-width: 210px !important;
}

::v-deep .vc-chrome-toggle-btn {
  display: none !important;
}

.mr-10 {
  margin-bottom: 10px;
}

.disabled-input-text {
  opacity: 0.4;
}

.custom-domain-input {
  width: 100% !important;
}

::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }

  .modal-wrapper {
    margin: 0 auto;
  }
}

.table-scroll {
  max-height: 12.5rem;
}

.radio-input-btn {
  margin-right: 15px;
  width: 4% !important;
  height: 20px !important;
  align-items: center;
  display: flex;
}

.account-domain-url {
  display: flex;
  align-items: 'center';
}

.application-dropdown-container {
  display: flex;
  width: 250px !important;
}

.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);

  .modal-header {
    margin-bottom: 10px;

    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }

    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }

  .modal-body {
    width: 600px;
  }

  .application-text {
    width: 31% !important;
    text-align: left;
  }

  .white-label-domain {
    display: flex;
    align-items: center;
    flex-direction: row;

    .form-check-label {
      width: 27.5%;
      text-align: left;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      padding: 9px;
    }

    .checkbox-btn {
      margin-left: 20px;
    }
  }

  .form-group {
    &.application-container {
      overflow: visible;

      .application-dropdown-container {
        //text-align: left;
        ::v-deep .dropdown {
          // min-width: auto !important;
          // width: 35%;
          // margin-right: 20px !important;
          .dropdown-menu {
            position: absolute !important;
          }
        }
      }
    }

    .advertiser-matches {
      width: 80%;
      background-color: #555555;
      max-height: 150px;
      overflow: hidden auto;
    }

    .advertiser-matches ul li {
      padding: 3px 10px;
      text-align: start;
    }

    .advertiser-matches ul li:hover {
      background-color: #444444;
    }

    &.favicon-container {
      display: flex;
      align-items: center;

      .favicon-input-container {
        width: 100%;
      }

      .form-check-label {
        width: unset;

        &.margin-right {
          margin-right: 114px;
        }

        &.account-domain-label {
          font-size: 12px;
          padding: unset;
          padding-left: 3px;
        }
      }
    }

    &.whitelabelbrandingContainer {
      display: flex;
      align-items: center;

      .white-label-domain-destination {
        width: 31%;
        text-align: left;
      }

      .white-label-subDomain-container {
        display: flex;
        align-items: center;

        .form-check-label {
          width: 100%;
        }

        .modal-body .form-input {
          margin-bottom: 0px;
        }
      }
    }

    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;

    &.width-50 {
      width: 50%;
      display: inline-block;

      label {
        width: 40%;
      }

      input {
        width: 60%;
      }
    }

    &.color-group {
      &::v-deep .vc-chrome {
        float: right;
      }

      &.overtext {
        overflow: visible;

        .overtext-dropdown-container {
          text-align: left;

          ::v-deep .dropdown {
            min-width: auto !important;
            width: 35%;
            margin-right: 20px !important;

            .dropdown-menu {
              position: absolute !important;
            }
          }
        }
      }

      .color-input-holder {
        width: 35%;
        float: left;
        height: 30px;
        font-size: 0.875rem;
        display: block;
        min-height: 40px;
        padding: 8px 10px;
        color: #cad1d6;
        background-color: #323239;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid rgba(170, 170, 170, 0.1);
      }

      input {
        border: none;
        height: 0;
        width: 0;
        overflow: hidden;
        padding: 0;

        &:hover {
          border: none;
        }
      }
    }

    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }

    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;

      p {
        text-align: left;
      }

      a {
        display: inline;
        color: #848890;
      }

      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }

    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;

      &.value {
        width: 50%;
      }
    }

    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);

      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }

    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }

    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;

      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }

    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);

      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }

    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}

::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}

::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}

::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}

.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}

.actions-btn {
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mg-bm-20 {
  margin-bottom: 20px;
}

::v-deep .checkbox-btn {
  margin-left: 122px;
  height: 40px;

  span {
    font-size: 14px;
    color: #cad1d6;
    letter-spacing: 0.07em;
  }

  svg {
    width: 18px;
    height: 18px;

    &.checkbox-inactive {
      color: #cad1d6;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      background-color: #323239;

      path {
        fill: #323239;
      }
    }
  }
}

::v-deep .long-label .checkbox-btn {
  span {
    height: 90px;
    margin-top: -17px;
    margin-left: 27px;
    line-height: 19px;
    text-align: left;
  }
}

.lg-control-box {
  display: inline-flex;
  width: 100%;

  label {
    width: 24% !important;
    padding: 9px 7px !important;
  }
}

::v-deep .form-input-wrap {
  height: 42px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;

  .form-click {
    border: none !important;
  }
}

::v-deep .modal-body .form-input .form-input-field.v-select {
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  //border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}

::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
  min-height: 38px !important;
}

::v-deep .form-input .form-input-field.v-select .vs__search {
  padding: 4px 6px;
  color: #cad1d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
}

::v-deep .form-input-wrap .form-click .action-icons svg,
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 14px;
}

::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}

// ::v-deep .v-select.vs--searchable.required.multiple-input-field.form-input-field {
//   left: -1px;
//   width: 100.5%;
// }
::v-deep .dropdown .dropdown-menu {
  position: initial !important;
}

::v-deep .modal-body .form-click {
  // border: 1px solid rgba(170, 170, 170, 0.1) !important;
  padding: 0;
  margin: 0;

  &.disabled {
    cursor: not-allowed;
  }
}

::v-deep .form-input .form-input-field.v-select .vs__selected-options {
  padding: 0 6px !important;
}

::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  padding: 0 6px !important;
  margin: 0 4px !important;
}

.vs__selected {
  color: #01abb7 !important;
  background-color: rgba(78, 78, 78, 0.4) !important;
}

::v-deep .form-input-wrap .form-click .selected-text {
  padding: 0 0 0 14px !important;
}

.mr-20 {
  margin-right: 20px;
}

.form-input-wrap .form-click .action-icons {
  padding: 0px !important;
}

::v-deep .user-form .dropdown {
  width: 80%;
  margin-right: 0 !important;
}

.no-bg {
  &:hover {
    background: transparent !important;
  }
}

.table-wrap {
  .tbl {
    thead {
      z-index: 1;

      th {
        &:last-child {
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        td {
          &:last-child {
            text-align: right;
          }
        }
      }
    }

    tr {
      td {
        &.action-column {
          overflow: visible;
        }
      }
    }
  }
}

::v-deep .wiz-btn-wide {
  .wide-btn {
    .k-btn.btn-size-3 {
      width: 145px !important;
    }
  }

  p {
    border-bottom: none !important;
  }

  .checkbox-btn {
    margin-left: 0 !important;
  }
}

.file-form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .file-group-label {
    width: 31%;
    display: inline-block;
    font-size: 0.875rem;
    color: #cad1d6;
    letter-spacing: 0.07em;
    padding: 9px;
    line-height: 20px;
  }

  .file-uploader-base {
    flex: 1;
  }

  .file-uploader-container {
    border: 2px dashed #888;
    flex: 1;
    margin-bottom: 15px;

    &.error {
      border-color: #ff0202;
    }

    &.dragging {
      border: 1px dashed #fff;
      color: #fff;
    }

    .file-input {
      display: none;
    }

    .file-input-label {
      padding: 53px 10px;
      display: block;
      cursor: pointer;

      svg {
        font-size: 20px !important;
      }
    }
  }

  .file-error-msg {
    text-align: left;
  }
}

.img-preview-container {
  text-align: left;
  margin-top: 20px;

  .img-container {
    height: 60px;
    display: inline-block;
    position: relative;
    background-color: #fff;

    .cross-icon-container {
      width: 15px;
      height: 15px;
      position: absolute;
      top: -7px;
      right: -7px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
    }

    img {
      height: 100%;
      width: auto;
    }

    .upload-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;

      .progress {
        height: 100%;
        background-color: rgba(256, 256, 256, 0.8);
      }

      .rest {
        height: 100%;
        background-color: rgba(256, 256, 256, 0.2);
      }

      .text {
        font-size: 16px;
        color: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.action-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.loading-message {
  font-style: italic;
  font-size: 14px;
  color: #cad1d6;
  flex: 1;
  text-align: left;

  .upload-percentage {
    margin-left: 4px;
    font-weight: 700;
  }
}

.save-favicon-label {
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.radio-input-btn {
  margin-right: 15px;
  width: 4% !important;
  height: 20px !important;
  align-items: center;
  display: flex;
}

::v-deep .txt-center {
  width: auto;
}

::v-deep .intip4 {
  position: relative;
  padding: 0 5px 0 8px;
  top: 0;

  svg {
    color: #fff !important;
    opacity: 0.75;

    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}

::v-deep .intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: auto;
  right: 25px;
  z-index: 99;
  display: block;
  width: 100px;
  white-space: break-spaces;
  padding: 4px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: rgba(245, 245, 250, 0.9);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: -20%;
}

.browse-txt {
  font-weight: 600;
  font-size: 14px;
}

.browse-btn {
  padding: 9px 19px;
  line-height: 22px;
  background-color: var(--adminprimarycolor);
  font-size: 12px;
  font-weight: 600;
  color: var(--adminovertext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 5px;
}

.colorpicker-account {
  position: relative;
}

.colorpicker-account .vc-chrome {
  padding-top: 20px;
  position: relative;
  display: block;
}

.colorpicker-account .colorpicker-close {
  right: 9px;
  position: absolute;
  z-index: 2;
  color: #4a4a4a;
  top: 3px;
  width: 10px;
  cursor: pointer;
}

.msg {
  padding: 26px;
  font-size: 20px;
  color: #69eb0d;
  letter-spacing: 0.9px;
}
</style>
